export const voteList = [
    {
        id: 0,
        content:'best all around'
    },
    {
        id: 1,
        content:'most unique'
    },  {
        id: 2,
        content:'perfect combo'
    },  {
        id: 3,
        content:'most desirable'
    },  {
        id: 4,
        content:'most unforgettable'
    },  {
        id: 5,
        content:'most famous looking'
    },  {
        id: 6,
        content:'my favorite'
    },  {
        id: 7,
        content:'most stylish'
    }
]