const HeaderImages = {
    filter: require("./images/filter.png"),
    hamburger: require("./images/hamburger.png"),
    twitter: require("./images/twitter.png"),
    insta: require("./images/insta.png"),
    redit: require("./images/redit.png"),
    opensea: require("./images/social-4.png"),
    logo_desk: require("./images/logo.png"),
    logo_mobile: require("./images/logomobile.png"),
    metamask_logo: require("./images/metamask.png"),
    plus: require("./images/plus.png"),
    minus: require("./images/minus.png"),
    btn1: require("./images/header-btn-1.png"),
    side1: require("./images/sidebar1.png"),
    side2: require("./images/sidebar2.png"),
    side3: require("./images/sidebar3.png"),
    side4: require("./images/sidebar4.png"),
    side5: require("./images/sidebar5.png"),
    side6: require("./images/sidebar6.png"),
    swap: require("./images/Swap_Objects.png")
};
const homepage = {
    header_desktop: require("./images/hero-layered-desktop.png")
};
const Masterplan = {
    hellow: require("./images/Hellothere_img.png"),
    cause: require("./images/image-the cause.png"),
    yearbook: require("./images/image-yearbook.png"),
};
const NFtImage = {
  yearbook1: require("./images/yearbook-1.png"),
  yearbook2: require("./images/yearbook-2.png"),
  yearbook3: require("./images/yearbook-3.jpg"),
  yearbook4: require("./images/yearbook-4.png"),
  yearbook5: require("./images/yearbook-5.jpg"),
  yearbook6: require("./images/yearbook-6.jpg"),
  yearbook7: require("./images/yearbook-7.jpg"),
  yearbook8: require("./images/yearbook-8.jpg"),
  yearbook9: require("./images/yearbook-9.jpg"),
  yearbook10: require("./images/yearbook-10.jpg"),
  yearbook11: require("./images/yearbook-11.png"),
  yearbook12: require("./images/yearbook-12.jpg"),
  yearbook13: require("./images/yearbook-13.jpg"),
  yearbook14: require("./images/yearbook-14.jpg"),
  yearbook15: require("./images/yearbook-15.jpg"),

};
const GalleryPopupImages = {
  check: require("./images/Verified_Checkmark.png"),
  mark: require("./images/Logomark-Blue 1.png"),
  yearbook: require("./images/yearbook-9.jpg"),
  xross :require('./images/x.png')
};
const MyMoolaImages = {
  coin: require("./images/coin.png"),
  Moolaimg1: require("./images/1st.jpg"),
  Moolaimg2: require("./images/2nd.jpg"),
  coin_icon: require("./images/icon-coin.png"),
  left: require("./images/Left.png"),

};
  export {
  HeaderImages,
  homepage,
  Masterplan,
  NFtImage,
  GalleryPopupImages,
  MyMoolaImages
  };