export const Background = [
    {
        id: 1,
        val:'DOTS'
    },
    {
        id: 2,
        val:'green'
    },    {
        id: 3,
        val:'pink'
    },    {
        id: 4,
        val:'red'
    },    {
        id: 5,
        val:'smokelite'
    },    {
        id: 6,
        val:'yellow'
    },    {
        id: 7,
        val:'tan'
    },    {
        id: 8,
        val:'heaven'
    },    {
        id: 9,
        val:'snow'
    },    {
        id: 10,
        val:'blue'
    },    {
        id: 11,
        val:'smokedark'
    },    {
        id: 12,
        val:'gears'
    },    {
        id: 13,
        val:'light'
    },    {
        id: 14,
        val:'action'
    },    {
        id: 15,
        val:'chalk'
    },
    {
        id: 16,
        val:'rain'
    },{
        id: 17,
        val:'cloud'
    },{
        id: 18,
        val:'night'
    },
    {
        id: 19,
        val:'nebula'
    },
    {
        id: 20,
        val:'spectrum'
    },

]
export const Body = [
    {
        id: 1,
        val:'cloud'
    },
    {
        id: 2,
        val:'green'
    },
    {
        id: 3,
        val:'heart'
    },
    {
        id: 4,
        val:'pink'
    },
    {
        id: 5,
        val:'purple'
    },
    {
        id: 6,
        val:'white'
    },
    {
        id: 7,
        val:'yellow'
    },
    {
        id: 8,
        val:'blue'
    },
    {
        id: 9,
        val:'brown'
    }, {
        id: 10,
        val:'milk'
    }, {
        id: 11,
        val:'red'
    }, {
        id: 12,
        val:'camoo'
    }, {
        id: 13,
        val:'splat'
    }, {
        id: 14,
        val:'chocolatemilk'
    }, {
        id: 15,
        val:'black'
    }, {
        id: 16,
        val:'dollar'
    }, {
        id: 17,
        val:'bitcoin'
    }, {
        id: 18,
        val:'stitches'
    }, {
        id: 19,
        val:'wood'
    }, {
        id: 20,
        val:'bones'
    }
]
export const Outfit = [
    {
        id: 1,
        val:'angel'
    },
    {
        id: 2,
        val:'argyle'
    },
    {
        id: 3,
        val:'flame'
    },
    {
        id: 4,
        val:'jewlery'
    },
    {
        id: 5,
        val:'joker'
    },
    {
        id: 6,
        val:'jumpsuite'
    },
    {
        id: 7,
        val:'king'
    },
    {
        id: 8,
        val:'prisoner'
    },
    {
        id: 9,
        val:'puffyvest'
    }, {
        id: 10,
        val:'raincoat'
    }, {
        id: 11,
        val:'tux'
    }, {
        id: 12,
        val:'scarf'
    }, {
        id: 13,
        val:'tank'
    }, {
        id: 14,
        val:'noregart'
    }, {
        id: 15,
        val:'hoodie'
    }, {
        id: 16,
        val:'miamispecial'
    }, {
        id: 17,
        val:' milman'
    }, {
        id: 18,
        val:'squidgame'
    }, {
        id: 19,
        val:'western'
    }, {
        id: 20,
        val:'hanbok'
    },
    {
        id: 21,
        val:'freddy'
    },
    {
        id: 22,
        val:'killbill'
    }, {
        id: 23,
        val:'blank'
    }, {
        id: 24,
        val:'caesar'
    }, {
        id: 25,
        val:' clockwork'
    }, {
        id: 26,
        val:'hooptop'
    }, {
        id: 27,
        val:'overall'
    }, {
        id: 28,
        val:'pilot'
    }, {
        id: 29,
        val:'thedude'
    }, {
        id: 30,
        val:'leon'
    }, {
        id: 31,
        val:'barrel'
    }, {
        id: 32,
        val:' beatit'
    }, {
        id: 33,
        val:'astronaught'
    }, {
        id: 34,
        val:'robocop'
    }, {
        id: 35,
        val:'mrt'
    },
    {
        id: 36,
        val:' namechain'
    }, {
        id: 37,
        val:'madhatter'
    }, {
        id: 38,
        val:'sacred'
    },
]
export const Accesories  = [
    {
        id: 1,
        val:'bandana'
    },
    {
        id: 2,
        val:'benie'
    },
    {
        id: 3,
        val:'sombrero'
    },
    {
        id: 4,
        val:'golden'
    },
    {
        id: 5,
        val:'bucket'
    },
    {
        id: 6,
        val:'crown'
    },
    {
        id: 7,
        val:'fedora'
    },
    {
        id: 8,
        val:'fisherman'
    },
    {
        id: 9,
        val:'flapcap'
    }, {
        id: 10,
        val:'greencap'
    }, {
        id: 11,
        val:'halo'
    }, {
        id: 12,
        val:'longhorn'
    }, {
        id: 13,
        val:'navycap'
    }, {
        id: 14,
        val:'partyhat'
    }, {
        id: 15,
        val:'prisoner'
    }, {
        id: 16,
        val:'pilot'
    }, {
        id: 17,
        val:' rasta'
    }, {
        id: 18,
        val:'showercap'
    }, {
        id: 19,
        val:'western'
    }, {
        id: 20,
        val:'soccer'
    },
    {
        id: 21,
        val:'watermelon'
    },
    {
        id: 22,
        val:'cone'
    }, {
        id: 23,
        val:'hardhat'
    }, {
        id: 24,
        val:'sacred'
    }, {
        id: 25,
        val:'bolo'
    }, {
        id: 26,
        val:'mrt'
    }, {
        id: 27,
        val:'astronaught'
    }, {
        id: 28,
        val:'casar'
    }, {
        id: 29,
        val:'gaat'
    }, {
        id: 30,
        val:'headband'
    }, {
        id: 31,
        val:'madhatter'
    }, {
        id: 32,
        val:' milkman'
    }, {
        id: 33,
        val:'pot'
    }, {
        id: 34,
        val:'robocop'
    }, {
        id: 35,
        val:'cheese'
    },
    {
        id: 36,
        val:' squareglass'
    }, {
        id: 37,
        val:'aviator'
    },
]
export const Eyes = [
    {
        id: 1,
        val:'angry'
    },
    {
        id: 2,
        val:' attention'
    },
    {
        id: 3,
        val:'heart'
    },
    {
        id: 4,
        val:'closed'
    },
    {
        id: 5,
        val:'confused'
    },
    {
        id: 6,
        val:'cross'
    },
    {
        id: 7,
        val:'focus'
    },
    {
        id: 8,
        val:'ghost'
    },
    {
        id: 9,
        val:'huh'
    }, {
        id: 10,
        val:'skeptical'
    }, {
        id: 11,
        val:'wtf'
    }, {
        id: 12,
        val:'depressed'
    }, {
        id: 13,
        val:'hypnotize'
    }, {
        id: 14,
        val:'shocked'
    }, {
        id: 15,
        val:'pop'
    }, {
        id: 16,
        val:'x'
    }, {
        id: 17,
        val:'fire'
    }, {
        id: 18,
        val:'monocle'
    }, {
        id: 19,
        val:'clockwork'
    }, {
        id: 21,
        val:'notsure'
    }
    , {
        id: 22,
        val:'heart'
    }, {
        id: 23,
        val:'highland'
    }, {
        id: 24,
        val:'battle'
    }, {
        id: 25,
        val:'gangsta'
    }, {
        id: 26,
        val:'puppy'
    }, {
        id: 27,
        val:'leon'
    }
]
export const Mouth = [
    {
        id: 1,
        val:'bubble'
    },
    {
        id: 2,
        val:'bubblepipe'
    },
    {
        id: 3,
        val:'heh'
    },
    {
        id: 4,
        val:'meh'
    },
    {
        id: 5,
        val:'nosering'
    },
    {
        id: 6,
        val:'nosering'
    },
    {
        id: 7,
        val:'pacifier'
    },
    {
        id: 8,
        val:'sacred'
    },
    {
        id: 9,
        val:'smile'
    }, {
        id: 10,
        val:'smile'
    }, {
        id: 11,
        val:'smily'
    }, {
        id: 12,
        val:'uh'
    }, {
        id: 13,
        val:'angry'
    }, {
        id: 14,
        val:'lipbit'
    }, {
        id: 15,
        val:'puff'
    }, {
        id: 16,
        val:'grr'
    }, {
        id: 17,
        val:'flower'
    }, {
        id: 18,
        val:'goldtooth'
    }, {
        id: 19,
        val:'lick'
    }, {
        id: 21,
        val:'tnt'
    }
    , {
        id: 22,
        val:'tongue'
    }, {
        id: 23,
        val:'bandana'
    }, {
        id: 24,
        val:'flame'
    }, {
        id: 25,
        val:'joint'
    }, {
        id: 26,
        val:'respirator'
    }, {
        id: 27,
        val:'gotmilk'
    },
    {
        id: 23,
        val:'guard'
    }, {
        id: 24,
        val:'kiss'
    }, {
        id: 25,
        val:'rainbow'
    }, {
        id: 26,
        val:'Copy'
    },
]
// export { 
//     Background,
//     Body
// }